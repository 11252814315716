@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.cdnfonts.com/css/myriad-pro');
/* BASIC */

html {
  background-color: #56baed;
}

body {
    overflow: hidden;
    font-family: "Poppins", sans-serif;
    height: 100vh;

    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
   
    background-image: url('./Fondo.svg');
  }


a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding-top: 50px;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #000000;
}



/* STRUCTURE */

.wrapper {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  background-size: cover; /* Ajusta el tamaño de la imagen para que cubra toda la pantalla */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  padding: 283px;
  
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  font-family: "Poppins", sans-serif;

  max-width: 700px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
  background-color: #fff;
}




/* TABS */

h2.inactive {
  
  color: #cccccc;
}

h2.active {
  color: #000000;
  font-family: 'Myriad Pro Bold', sans-serif;
  border-bottom: 2px solid #e7e95f;
}



/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset]  {
  border: none;
  color: rgb(0, 0, 0);
  padding: 15px 80px;
  text-align: center;
  font-family: 'Myriad Pro Regular', sans-serif;
                                                
  display: inline-block;
  
  text-transform: uppercase;
  font-size: 15px;
 
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}
.button-yellow {
  margin: 5px 20px 10px 10px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(219, 233, 95, 0.4);
  box-shadow: 0 10px 30px 0 rgba(212, 233, 95, 0.4);
  background-color: #e2f76b;
  /* Otras propiedades de estilo aquí */
}

.button-yellow:hover {

  background-color: #c9ec4b;
}

.button-yellow:active {
  transform: scale(0.95);
}
.button-blue {
  margin: 5px 20px 10px 10px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 141, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 134, 233, 0.4);
  background-color: #6bc1f7;
  /* Otras propiedades de estilo aquí */
}

.button-blue:hover {
  background-color: #4bb4ec;
}

.button-blue:active {
  transform: scale(0.95);
}

.button-orange {
  margin: 5px 20px 60px 10px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(233, 187, 95, 0.4);
  box-shadow: 0 10px 30px 0 rgba(233, 176, 95, 0.4);
  background-color: #f7b86b;
  /* Otras propiedades de estilo aquí */
}

.button-orange:hover {
  background-color: #ec934b;
}

.button-orange:active {
  transform: scale(0.95);
}
input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #e9e95f;
}

input[type=text]:placeholder {
  color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {

  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;

    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #eded56;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

* {
  box-sizing: border-box;
}